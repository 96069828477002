import React from 'react';

import './result-chart.scss';

import O from './../images/ope.png';
import O2 from './../images/clo.png';
import C from './../images/conc.png';
import C2 from './../images/unc.png';
import E from './../images/ext.png';
import E2 from './../images/int.png';
import A from './../images/agr.png';
import A2 from './../images/dis.png';
import S from './../images/sta.png';
import S2 from './../images/neu.png';

const ResultChart = ({ facetType, level }) => {

    let leftSymbol;
    let rightSymbol;

    let leftText = "Left text";
    let rightText = "Right text";

    const opeColor1 = "#4DB5C1";
    const opeColor2 = "#10BFA0";

    const conColor1 = "#D97F3B";
    const conColor2 = "#EDC771";

    const extColor1 = "#F39770";
    const extColor2 = "#BC3D3D";

    const agrColor1 = "#4EA265";
    const agrColor2 = "#A8E393";

    const staColor1 = "#9157B3";
    const staColor2 = "#9157B3";


    let graphColor1 = conColor1;
    let graphColor2 = conColor2;


    switch (facetType) {
        case "ope":
            leftSymbol = O2;
            rightSymbol = O;

            leftText = "Closed to experience";
            rightText = "Open to experience";

            graphColor1 = opeColor1;
            graphColor2 = opeColor2;
            break;
        case "con":
            leftSymbol = C2;
            rightSymbol = C;

            leftText = "Unstructured";
            rightText = "Structured";

            graphColor1 = conColor1;
            graphColor2 = conColor2;

            break;
        case "ext":
            leftSymbol = E2;
            rightSymbol = E;

            leftText = "Introverted";
            rightText = "Extraverted";

            graphColor1 = extColor1;
            graphColor2 = extColor2;
            break;
        case "agr":
            leftSymbol = A2;
            rightSymbol = A;

            leftText = "Disagreeable";
            rightText = "Agreeable";

            graphColor1 = agrColor1;
            graphColor2 = agrColor2;
            break;
        case "sta":
            leftSymbol = S2;
            rightSymbol = S;

            leftText = "Emotionally Variant";
            rightText = "Emotionally Stable";

            graphColor1 = staColor1;
            graphColor2 = staColor2;
            break;
        default:
            leftSymbol = O2;
            rightSymbol = O;

    }


    let translateSelector = 0;
    let translateAmount = (100 / 7);

    translateSelector = (translateAmount * level) - 1;

    let idString = "grad" + facetType;
    let fillString = "url(#grad" + facetType + ")";

    return (
        <div class="result-chart">
            {/*<div class="result-chart-title"> Moderately open to experience  </div>*/}
            <div class="result-chart-middle">
                <div class="result-chart-side">
                    <img className="result-chart-side-logo" src={leftSymbol} width="100%" />
                    {leftText}
                </div>
                <div class="result-chart-center" >
                    <svg width="100%" height="100%" viewBox="0 0 242 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <defs>
                            <linearGradient id={idString} x1="0%" x2="100%" y1="0%" y2="0%">
                                <stop offset="0%" stop-color={graphColor1} />
                                <stop offset="100%" stop-color={graphColor2} />
                            </linearGradient>
                        </defs>

                        <path d="M239.823 160L0 160L11.9522 158.494C11.9522 158.494 24.8874 157.553 32.51 154.477C39.3846 151.702 42.8187 148.999 48.7649 144.435C69.4186 128.584 85.0996 87.1967 85.0996 87.1967C85.0996 87.1967 98.5042 40.0357 120.956 40C143.554 39.9641 156.813 87.6988 156.813 87.6988C156.813 87.6988 174.535 126.541 194.104 143.431C199.906 148.439 203.028 151.752 209.88 154.979C220.53 159.995 239.823 160 239.823 160Z"
                            fill={fillString} />

                        <line x1="0.5" y1="40" x2="0.500005" y2="160" stroke="white" stroke-opacity="0.2" />
                        <line x1="34.8008" y1="40" x2="34.8008" y2="160" stroke="white" stroke-opacity="0.2" />
                        <line x1="69.0996" y1="40" x2="69.0996" y2="160" stroke="white" stroke-opacity="0.2" />
                        <line x1="103.4" y1="40" x2="103.4" y2="160" stroke="white" stroke-opacity="0.2" />
                        <line x1="137.699" y1="40" x2="137.699" y2="160" stroke="white" stroke-opacity="0.2" />
                        <line x1="172" y1="40" x2="172" y2="160" stroke="white" stroke-opacity="0.2" />
                        <line x1="206.301" y1="40" x2="206.301" y2="160" stroke="white" stroke-opacity="0.2" />
                        <line x1="240.6" y1="40" x2="240.6" y2="160" stroke="white" stroke-opacity="0.2" />
                    </svg>
                    <div class="result-chart-selector-container" style={{ transform: "translate(" + translateSelector + "%)" }}>
                        <div class="result-chart-selector">
                            <svg width="100%" height="100%" viewBox="0 0 241 180" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect opacity="0.25" x="1" y="40" width="34" height="119" fill="url(#paint0_linear_4349_10602)" />
                                <line x1="1.31134e-07" y1="169.5" x2="35" y2="169.5" stroke="url(#paint1_linear_4349_10602)" stroke-width="3" />
                                <path d="M18.5001 28.7784L11.1406 13.1945L25.8629 13.196L18.5001 28.7784Z" fill="url(#paint2_linear_4349_10602)" />
                                <defs>
                                    <linearGradient id="paint0_linear_4349_10602" x1="18" y1="40" x2="18" y2="159" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#EDEDED" />
                                        <stop offset="1" stop-color="#787878" />
                                    </linearGradient>
                                    <linearGradient id="paint1_linear_4349_10602" x1="-4.37114e-08" y1="171.5" x2="35" y2="171.5" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#EDEDED" />
                                        <stop offset="1" stop-color="#787878" />
                                    </linearGradient>
                                    <linearGradient id="paint2_linear_4349_10602" x1="18.5023" y1="8.00088" x2="18.5001" y2="28.7784" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#EDEDED" />
                                        <stop offset="1" stop-color="#787878" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </div>
                    </div>
                    <div class="result-chart-bottom">
                        <svg width="100%" height="100%" viewBox="0 0 241 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 3L5 5.88675L5 0.113248L0 3ZM241 2.99998L236 0.113228L236 5.88673L241 2.99998ZM4.5 3.5L236.5 3.49998L236.5 2.49998L4.5 2.5L4.5 3.5Z" fill="white" />
                        </svg>
                    </div>
                    <div class="result-chart-bottom-text">
                        <div class="result-chart-bottom-text-section">
                            Highly {leftText}
                        </div>
                        <div class="result-chart-bottom-text-section">
                            Average
                        </div>
                        <div class="result-chart-bottom-text-section">
                            Highly {rightText}
                        </div>
                    </div>
                </div>
                <div class="result-chart-side">
                    <img src={rightSymbol} className="result-chart-side-logo" width="100%" />
                    {rightText}
                </div>
            </div>
        </div>
    )
}


export default ResultChart;
