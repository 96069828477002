
export const opeResultsText = {


ope1text1: `
People who are highly closed to experience strongly favour existing routines and following traditional ideas over novel ones. They find their preferred routine, and they stick to it. They are not interested in discussing abstract ideas. They are not particularly interested in artistic endeavours or pursuing creativity. 
`,
ope1text2: `
Highly closed people do not enjoy diverging from their established routines. They aim to live their life in a manner where they don’t have to experience too much novelty, and would prefer to stick to what they know. They do not find much value in art or in fanciful, imaginative ideas.


`,
ope1text3: `
People who are highly closed to experience are grounded realists. They do not want to waste their energy talking about abstractions or pursuing novel, creative endeavours. They are less verbally fluid than people who are open to experience. They are not big risk-takers, tending to think in more concrete, traditional terms than the average person. 

`
,



ope2text1: `
People who are moderately closed to experience favour existing routines and follow traditional ideas over novel ones. They find their preferred routine, and they stick to it. They are not interested in discussing abstract ideas. They are not particularly interested in artistic endeavours or pursuing creativity. 
`,

ope2text2: `
Moderately closed people do not enjoy diverging from their established routines. They aim to live their life in a manner where they don’t have to experience too much novelty, and would prefer to stick to what they know. They do not find much value in art or in fanciful, imaginative ideas.
`,

ope2text3:`
People who are moderately closed to experience are grounded realists. They do not want to waste their energy talking about abstractions or pursuing novel, creative endeavours. They are less verbally fluid than people who are open to experience. They are not big risk-takers, tending to think in more concrete, traditional terms than the average person. 
 `,




ope3text1: `People who are somewhat closed to experience have a preference for existing routines and following traditional ideas over novel ones. They would generally aim to find their preferred routine, and stick to it. They are not particularly interested in discussing abstract ideas. They are not overly interested in artistic endeavours or pursuing creativity. 
 
`,
ope3text2: `Somewhat closed people generally do not enjoy diverging from their established routines. They aim to live their life in a manner where they don’t have to experience too much novelty, and would prefer to stick to what they know. They do not find much value in art or in fanciful, imaginative ideas.
`,
ope3text3: `
People who are somewhat closed to experience can be grounded realists. They may not want to waste their energy talking about abstractions or pursuing novel, creative endeavours. They can be less verbally fluid than people who are open to experience. They are generally not big risk-takers, tending to think in more concrete, traditional terms than the average person. 
 `,





ope4text1: `
People who are neither open nor closed to experience are not particularly open to experience, or closed to experience when compared to others.

`,
ope4text2: `
People who are open to experience are interested in pursuing novelty, creativity, and aesthetics. They are unorthodox and enjoy shaking up the status quo. People who are closed to experience are not particularly interested in novel ideas or experiences. They prefer to find an established routine and stick to it. People who are neither open nor closed are not overly interested in pursuing originality, but they are also not particularly closed to it. There are times when they will want to experience something novel, and times when they will prefer to fall back on an existing routine. 
  `,
ope4text3: `People who are open to experience enjoy talking about ideas and have a strong imagination. People who are closed to experience are not particularly interested in talking about abstractions, and tend to be more grounded in their thinking. People who are somewhere in-between are flexible and will find some value in talking about ideas and abstractions, but not to an extreme degree. 
`,

ope5text1:`  People who are somewhat open to experience have an interest in novel experiences and originality. They are somewhat more likely to be interested in unorthodox ideas, music, and experiences than people who are closed to experience. They may be interested in ideas and aesthetics. They are also more likely to want to pursue creative endeavours than the average person. 


`,
ope5text2:`Somewhat open people may enjoy experiencing new things. They tend to not enjoy too much rigidity and routine in their lifestyles, and may shake things up to pursue something better or just for the sake of it. They have more of an interest in art, aesthetics, poetry and imagination than people who are closed to experience.

`,
ope5text3:`
People who are somewhat open to experience can have an interest in ideas. They are somewhat more likely to fluidly make connections between ideas than people who are closed to experience. People who are somewhat open to experience can be creative, and may have a drive to create original works and live their life in a creative manner. They aim to avoid situations that involve too much repetition or where they cannot exercise their creativity.   
`,



ope6text1:`People who are moderately open to experience seek novel experiences and value originality. They are more likely to be interested in unorthodox ideas, music, and experiences than people who are closed to experience. They are captivated by ideas and aesthetics. They are also likely to pursue creative endeavours. 

`,
ope6text2:`Moderately open people enjoy experiencing new things. They do not enjoy too much rigidity and routine in their lifestyles, and will often shake things up to pursue something better or just for the sake of it. They value art and aesthetics. They enjoy poetry and imagination. 

`,
ope6text3:`People who are moderately open to experience are interested in ideas. They are more likely to fluidly make connections between ideas than people who are closed to experience. People who are moderately open to experience are creative, and have a drive to create original works and live their life in a creative manner. They aim to avoid situations that involve too much repetition or where they cannot exercise their creativity. 

`,

ope7text1:`
People who are highly open to experience strongly seek novel experiences and value originality. They are much more likely to be interested in unorthodox ideas, music, and experiences than people who are closed to experience. They are highly captivated by ideas and aesthetics. They are also highly likely to pursue creative endeavours. 
`,
ope7text2:`

Highly open people strongly enjoy experiencing new things. They do not enjoy rigidity and routine in their lifestyles, and will often shake things up to pursue something better or just for the sake of it. They highly value art and aesthetics. They enjoy poetry and imagination. 

`,
ope7text3:`People who are highly open to experience are highly interested in ideas. They are much more likely to fluidly make connections between ideas than people who are closed to experience. People who are highly open to experience are creative, and have a strong drive to create original works and live their life in a creative manner. They aim to avoid situations that involve too much repetition or where they cannot exercise their creativity.  `


}

