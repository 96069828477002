const questionList = [
    { trait: 'S', score: -1, question: 'I get stressed out easily.' },
    { trait: 'S', score: -1, question: 'I worry about things. ' },
    { trait: 'S', score: -1, question: 'I am easily disturbed. ' },
    { trait: 'S', score: -1, question: 'I get upset easily. ' },
    { trait: 'S', score: -1, question: 'I change my mood a lot.  ' },
    { trait: 'S', score: -1, question: 'I have frequent mood swings. ' },
    { trait: 'S', score: -1, question: 'I get irritated easily. ' },
    { trait: 'S', score: -1, question: 'I often feel blue.'},
    { trait: 'S', score: 1, question: 'I seldom feel blue.  ' },
    { trait: 'S', score: 1, question: 'I am relaxed most of the time.  ' },

    { trait: 'O', score: 1, question: 'I have a rich vocabulary. ' },
    { trait: 'O', score: 1, question: 'I have a vivid imagination. ' },
    { trait: 'O', score: 1, question: 'I have excellent ideas. ' },
    { trait: 'O', score: 1, question: 'I am quick to understand things. ' },
    { trait: 'O', score: 1, question: 'I use difficult words. ' },
    { trait: 'O', score: 1, question: 'I spend time reflecting on things.' },
    { trait: 'O', score: 1, question: 'I am full of ideas. ' },
    { trait: 'O', score: -1, question: 'I have difficulty understanding abstract ideas.'},
    { trait: 'O', score: -1, question: 'I am not interested in abstract ideas.  ' },
    { trait: 'O', score: -1, question: 'I do not have a good imagination. ' },

    { trait: 'C', score: 1, question: 'I am always prepared. ' },
    { trait: 'C', score: 1, question: 'I pay attention to details.' },
    { trait: 'C', score: 1, question: 'I get chores done right away.' },
    { trait: 'C', score: 1, question: 'I like order.' },
    { trait: 'C', score: 1, question: 'I follow a schedule.' },
    { trait: 'C', score: 1, question: 'I am exacting in my work.' },
    { trait: 'C', score: -1, question: 'I leave my belongings around. ' },
    { trait: 'C', score: -1, question: 'I make a mess of things. '},
    { trait: 'C', score: -1, question: 'I often forget to put things back in their proper place.  ' },
    { trait: 'C', score: -1, question: 'I shirk my duties. ' },

      { trait: 'E', score: 1, question: 'I am the life of the party.' },
    { trait: 'E', score: 1, question: 'I feel comfortable around people.' },
    { trait: 'E', score: 1, question: 'I start conversations. ' },
    { trait: 'E', score: 1, question: 'I talk to a lot of different people at parties.' },
    { trait: 'E', score: 1, question: 'I don\'t mind being the center of attention. ' },
    { trait: 'E', score: -1, question: 'I don\'t talk a lot.' },
    { trait: 'E', score: -1, question: 'I keep in the background.  ' },
    { trait: 'E', score: -1, question: 'I have little to say. '},
    { trait: 'E', score: -1, question: ' I don\'t like to draw attention to myself.  ' },
    { trait: 'E', score: -1, question: 'I am quiet around strangers.  ' },

    { trait: 'A', score: 1, question: 'I am interested in people.' },
    { trait: 'A', score: 1, question: 'I sympathize with others\' feelings. ' },
    { trait: 'A', score: 1, question: 'I have a soft heart. ' },
    { trait: 'A', score: 1, question: 'I take time out for others.' },
    { trait: 'A', score: 1, question: 'I feel others\' emotions.' },
    { trait: 'A', score: 1, question: 'I make people feel at ease.' },
    { trait: 'A', score: -1, question: 'I am not really interested in others.  ' },
    { trait: 'A', score: -1, question: 'I insult people. '},
    { trait: 'A', score: -1, question: 'I am not interested in other people\'s problems.  ' },
    { trait: 'A', score: -1, question: 'I feel little concern for others. ' },
	
	

]


export default questionList;