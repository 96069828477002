




const sanitize = ( error ) => {


    if (error== "Firebase: Error (auth/missing-email).") {
        error = "Please enter an email";
      }
    if (error== "Firebase: Error (auth/invalid-email).") {
        error = "Invalid email";
      }

      if (error== "Firebase: Error (auth/email-already-exists).") {
        error = "Email already exists. Please enter a unique email";
      }

      if (error== "Firebase: Error (auth/email-already-in-use).") {
        error = "That email is already in use";
      }

      if (error == "Firebase: Error (auth/missing-password).") {
        error = "Please enter a password";
      }

      if (error== "Firebase: Error (auth/invalid-password).") {
        error = "Invalid password. Must be over 6 characters";
      }

      if (error == "Firebase: Error (auth/invalid-credential).") {
        error = "Password is incorrect";
      }
      if (error == "Firebase: Password should be at least 6 characters (auth/weak-password).") {
        error = "Invalid password. Must be over 6 characters";
      }

      return error;
}


export default sanitize; 