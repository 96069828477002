
export const staResultsText = {


sta1text1: `People who are highly emotionally unstable are much more prone to experience negative mental states, such as anxiety, depression, and anger than the average person. They fluctuate in their emotions, and feel their emotions deeply. When a negative event happens, it affects them with a high degree of intensity and takes them a long time to calm down.  
`,
sta1text2: `
Highly emotionally unstable people worry a lot about negative things happening to them, and about potential threats. They can struggle with problems with anxiety and have difficulties remaining calm in situations where they are under pressure. Because they have a more pessimistic outlook in life, they may notice problems that others don’t. They exhibit a high degree of caution in their approach to life, and do not enjoy being in overly stressful environments. 

`,
sta1text3: `People who are highly emotionally unstable are much more self-aware and more self-critical than emotionally stable people. Depending on the situation, this self-awareness can be a benefit and it can be a liability. In social environments, highly emotionally unstable people are shy and warm up to people slowly. 
`
,



sta2text1: `
People who are moderately emotionally unstable are more prone to experience negative mental states, such as anxiety, depression, and anger than the average person. They fluctuate in their emotions, and feel their emotions deeply. When a negative event happens, it affects them with a high degree of intensity and takes them a long time to calm down. 

`,

sta2text2: `Moderately emotionally unstable people worry about negative things happening to them, and about potential threats. They can struggle with problems with anxiety and have difficulties remaining calm in situations where they are under pressure. Because they have a more pessimistic outlook in life, they may notice problems that others don’t. They exhibit a high degree of caution in their approach to life,, and do not enjoy being in overly stressful environments. 
`,

sta2text3:`People who are moderately emotionally unstable are more self-aware and more self-critical than emotionally stable people. Depending on the situation, this self-awareness can be a benefit and it can be a liability. In social environments, moderately emotionally unstable people are shy and warm up to people slowly. 
`,




sta3text1: `People who are somewhat emotionally unstable are somewhat more prone to experience negative mental states, such as anxiety, depression, and anger than the average person. They may fluctuate in their emotions, and can feel their emotions deeply. When a negative event happens, it can affect them with a higher degree of intensity and can take them a longer time to calm down than an emotionally stable person. 
`,
sta3text2: `Somewhat emotionally unstable people tend to worry about negative things happening to them, and about potential threats. They can struggle with problems with anxiety and have difficulties remaining calm in situations where they are under pressure. Because they can have a more pessimistic outlook in life, they may notice problems that others don’t. They tend to exhibit a moderate degree of caution in their approach to life, and may not enjoy being in overly stressful environments. 
`,
sta3text3: `
People who are moderately emotionally unstable can be more self-aware and more self-critical than emotionally stable people. Depending on the situation, this self-awareness can be a benefit and it can be a liability. In social environments, somewhat emotionally unstable people may be shy and warm up to people slower than the average person. 
  `,



sta4text1: `People who are neither emotionally stable nor emotionally unstable are not particularly high in emotional stability or emotional instability compared to others. 
`,
sta4text2: `
People who are emotionally stable are not bothered by stressful events in their life, and do not fluctuate wildly in their mood. People who are emotionally unstable are more deeply affected by negative events, and feel negative emotions strongly. People who are somewhere in-between are not overly prone to negative mental states, and they are not overly resistant to them. There will be times when they worry about things and take them to heart, and times where they’re not bothered and brush it off. 
`,
sta4text3: ` People who are emotionally stable don’t worry much about negative possibilities, and are easygoing in their life outlook. People who are emotionally unstable worry a lot, and tend to be tense and self-critical. People who are neither emotionally stable nor emotionally unstable are somewhere in-between. There will be times when they worry, and times where they don’t care too much. 
`,

sta5text1:` 
People who are somewhat emotionally stable generally do not swing rapidly between emotional states, and are less easily perturbed by stressful situations compared to other people. They react with less negative emotion to negative or disturbing events than others. They tend to be calm, collected and less likely to be afflicted with negative mental states such as depression and anxiety than people who are emotionally variant. 

`,
sta5text2:`Somewhat emotionally stable people don’t tend to change much in their emotional reaction to events in their life. It takes a bit more pressure and negativity to affect a somewhat emotionally stable person than the average person. For this reason, they can do well in stressful environments, and may even intentionally seek them out. They may not worry about possible problems, and jump into situations without worrying about risks. `,

sta5text3:`
Because emotionally stable people tend to be less focused on risks and worry less, they can be overly self-confident and self-assured. They may be less self-critical than emotionally unstable people. Their lack of focus on potential problems can get them into trouble. In social situations, they are easy to get to know and not overly shy or self-conscious. 

`,



sta6text1:`People who are moderately emotionally stable do not swing rapidly between emotional states, and are unaffected by negative events happening to them. They are less likely to be affected by negative mental states such as depression, anxiety, and anger than emotionally unstable people.  They react to stress well, and can have a more positive outlook on life than emotionally unstable people.
`,
sta6text2:`Moderately emotionally stable people do not change much in their emotional reaction to events in their life. It takes more pressure and negativity to affect a moderately emotionally stable person than the average person. For this reason, they do well in stressful environments, and may even intentionally seek them out. They do not worry about possible problems, and jump into situations without worrying about risks.


`,
sta6text3:`
Because emotionally stable people are less focused on risks and worry less, they can be overly self-confident and self-assured. They are less self-critical than emotionally unstable people. Their lack of focus on potential problems can get them into trouble. In social situations, they are easy to get to know and not overly shy or self-conscious. 


`,

sta7text1:`
People who are highly emotionally stable do not swing rapidly between emotional states at all, and are very unaffected by negative events happening to them.  They are much less likely to be affected by negative mental states such as depression, anxiety, and anger than emotionally unstable people.  They react to stress very well, and can have a more positive outlook on life than emotionally unstable people.
`,
sta7text2:`
Highly emotionally stable people do not change much in their emotional reaction to events in their life. It takes a lot more pressure and negativity to affect a highly emotionally stable person than the average person. For this reason, they do well in stressful environments, and may even intentionally seek them out. They do not worry about possible problems, and jump into situations without worrying about risks.
`,
sta7text3:`
Because emotionally stable people are less focused on risks and worry less, they can be overly self-confident and self-assured. They are less self-critical than emotionally unstable people. Their lack of focus on potential problems can get them into trouble. In social situations, they are easy to get to know and not overly shy or self-conscious. 


`


}

