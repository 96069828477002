
export const conResultsText = {


con1text1: `
People who are highly unstructured are very spontaneous and unordered. They have difficulties sticking to plans and resisting impulsive behaviour. They don’t enjoy being in environments that are overly structured, and enjoy having free time and living in the moment. They tend to live in more cluttered environments and are less organised than structured people.





`,
con1text2: `Highly unstructured people find it very hard to sacrifice short-term pleasure for longer term goals. They are more likely to fall into addictions than the average person. They are spontaneous and bombastic, taking a disorganised, chaotic approach to life rather than an organised one. They have difficulties meeting deadlines and keeping to schedules.

`,
con1text3: `People who are highly unstructured are not overly bothered by mess. They are less sensitive to disgust than structured people. They are also less judgemental. People who are highly unstructured tend to take themselves less seriously, and are not uptight or rigid in their thinking. Their spontaneous approach to life can make them more fun in social environments. 


`
,



con2text1: `
People who are moderately unstructured are spontaneous and unordered. They have difficulties sticking to plans and resisting impulsive behaviour. They don’t enjoy being in environments that are overly structured, and enjoy having free time and living in the moment. They tend to live in more cluttered environments and are less organised than structured people.
 
`,

con2text2: `Moderately unstructured people find it hard to sacrifice short-term pleasure for longer term goals. They are more likely to fall into addictions than the average person. They are spontaneous and bombastic, taking a disorganised, chaotic approach to life rather than an organised one. They have difficulties meeting deadlines and keeping to schedules.

`,

con2text3:`People who are moderately unstructured are not overly bothered by mess. They are less sensitive to disgust than structured people. They are also less judgemental. People who are unstructured tend to take themselves less seriously, and are not uptight or rigid in their thinking. Their spontaneous approach to life can make them more fun in social environments. 
`,




con3text1: `People who are somewhat unstructured are more spontaneous and unordered in their behaviour than the average person. They can have difficulties sticking to plans and resisting impulsive behaviour. They may not enjoy being in environments that are overly structured, and generally enjoy having free time and living in the moment. They tend to live in more cluttered environments and are less organised than structured people.

`,
con3text2: `Somewhat unstructured people may find it hard to sacrifice short-term pleasure for longer term goals. They are more likely to fall into addictions than the average person. They may be spontaneous and bombastic, taking a disorganised, chaotic approach to life rather than an organised one. They can have difficulties meeting deadlines and keeping to schedules.




`,
con3text3: `People who are somewhat unstructured are not overly bothered by mess. They are less sensitive to disgust than structured people. They are also less judgemental. People who are unstructured tend to take themselves less seriously, and are not uptight or rigid in their thinking. Their spontaneous approach to life can make them more fun in social environments.  `,





con4text1: `People who are neither structured nor unstructured are not particularly high in structuredness or unstructuredness compared with others.  `,
con4text2: `

People who are structured are orderly and follow routines. Unstructured people are more spontaneous and can struggle with engaging in planned behaviour. People who are neither structured nor unstructured are somewhere in-between, not highly structured and diligent, nor overly spontaneous and chaotic.
 `,
con4text3: ` 
Structured people like clean, organised environments. Unstructured people don’t mind mess and are less judgemental than structured people. People who are neither structured nor unstructured are not super strict and tidy, nor are they super messy. They can handle a little bit of mess, spontaneity, and chaos in their life, but not to an extreme degree. 
  `,

con5text1:`People who are somewhat structured have a tendency to be orderly. They prefer to follow routines and make plans. They engage in somewhat more planned behaviour than unstructured people. They tend to be dutiful and find meaning in working and in being busy. They have an interest in self-discipline and maintaining clean environments around them. 

`,
con5text2:`Somewhat structured people are more willing to sacrifice short-term pleasure for long-term goals than the average person. They generally find value in working hard and can be unhappy if they don’t have enough to do. They are more resistant to addictions than unstructured people. Somewhat structured people tend to be achievers, willing to work hard to attain their goals. 


`,
con5text3:`
People who are somewhat structured have a preference for clean, orderly environments around them, and do not overly enjoy clutter or mess. They can be more judgemental than the average person, and look down upon those who they perceive as not doing their duty. They may also be more sensitive to disgust. Structured people tend to want to organise the world around them and put things into neat categories. 

`,



con6text1:`People who are moderately structured are orderly. They follow routines, and make plans. They engage in more planned behaviour than unstructured people. They are dutiful and find meaning in working and in being busy. They value self-discipline and maintain clean environments around them. 

`,
con6text2:`
Moderately structured people are willing to sacrifice short-term pleasure for long-term goals. They find value in working hard and are unhappy if they don’t have enough to do. They are more resistant to addictions than unstructured people. Moderately structured people are achievers, willing to work hard to attain their goals. 

`,
con6text3:`
People who are moderately structured maintain clean, orderly environments around them, and do not enjoy clutter or mess. They are more judgemental than the average person, and look down upon those who they perceive as not doing their duty. They are also more sensitive to disgust. Structured people tend to want to organise the world around them and put things into neat categories. 
`,

con7text1:`
People who are highly structured are very orderly. They follow routines and make plans. They engage in much more planned behaviour than unstructured people. They are dutiful and find a lot of meaning in working and in being busy. They highly value self-discipline and maintain clean environments around them. 

`,
con7text2:`
Highly structured people are very willing to sacrifice short-term pleasure for long-term goals. They find a lot of value in working hard and are unhappy if they don’t have enough to do. They are more resistant to addictions than unstructured people. Highly structured people are achievers, willing to work hard to attain their goals. 


`,
con7text3:`People who are highly structured maintain clean, orderly environments around them, and do not enjoy clutter or mess. They are more judgemental than the average person, and look down upon those who they perceive as not doing their duty. They are also more sensitive to disgust. Highly structured people tend to want to organise the world around them and put things into neat categories.  `


}

