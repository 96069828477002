
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg } from 'html-to-image';


import { opeResultsText } from './../text/ope-results-text.js';
import { conResultsText } from './../text/con-results-text.js';
import { extResultsText } from './../text/ext-results-text.js';
import { agrResultsText } from './../text/agr-results-text.js';
import { staResultsText } from './../text/sta-results-text.js';
import { strengthsText } from './../text/strengths-text.js';


import Logo from './../images/logo.png';

import O from './../images/ope.png';
import O2 from './../images/clo.png';
import C from './../images/conc.png';
import C2 from './../images/unc.png';
import E from './../images/ext.png';
import E2 from './../images/int.png';
import A from './../images/agr.png';
import A2 from './../images/dis.png';
import S from './../images/sta.png';
import S2 from './../images/neu.png';

import OpeClo from './../images/ope_clo.png';
import ConUnc from './../images/con_unc.png';
import ExtInt from './../images/ext_int.png';
import AgrDis from './../images/agr_dis.png';
import EmoNeu from './../images/emo_neu.png';

//import Strength from './../images/strength.png';

import OpeStrength from './../images/ope_s.png';
import OpeWeakness from './../images/ope_w.png';
import CloStrength from './../images/clo_s.png';
import CloWeakness from './../images/clo_w.png';

import ConStrength from './../images/con_s.png';
import ConWeakness from './../images/con_w.png';
import UncStrength from './../images/unc_s.png';
import UncWeakness from './../images/unc_w.png';

import ExtStrength from './../images/ext_s.png';
import ExtWeakness from './../images/ext_w.png';
import IntStrength from './../images/int_s.png';
import IntWeakness from './../images/int_w.png';

import AgrStrength from './../images/agr_s.png';
import AgrWeakness from './../images/agr_w.png';
import DisStrength from './../images/dis_s.png';
import DisWeakness from './../images/dis_w.png';

import EmoStrength from './../images/emo_s.png';
import EmoWeakness from './../images/emo_w.png';
import NeuStrength from './../images/neu_s.png';
import NeuWeakness from './../images/neu_w.png';


import React from 'react';
import { useState, useEffect } from 'react';


import Strength from './../images/ope_s.png';
import Weakness from './../images/ope_w.png';

import { Link, useNavigate } from 'react-router-dom';

import ResultChart from './../components/result-chart';

import './results.scss';
import './pre-test.scss';
import './../global-variables.scss';


import { motion } from 'framer-motion';


const Results = ({ personalityData, setModalOpen }) => {

  const imageWhileHoverAnimation = {
    y: [null, -5, 0],
    transition: { duration: 0.3 },
  };


  let opeTitle;
  let opeText1;
  let opeText2;
  let opeText3;

  let conTitle;
  let conText1;
  let conText2;
  let conText3;

  let extTitle;
  let extText1;
  let extText2;
  let extText3;

  let agrTitle;
  let agrText1;
  let agrText2;
  let agrText3;

  let staTitle;
  let staText1;
  let staText2;
  let staText3;

  let opeStrengths1;
  let opeStrengths2;
  let opeStrengths3;
  let opeWeaknesses1;
  let opeWeaknesses2;
  let opeWeaknesses3;

  let conStrengths1;
  let conStrengths2;
  let conStrengths3;
  let conWeaknesses1;
  let conWeaknesses2;
  let conWeaknesses3;

  let extStrengths1;
  let extStrengths2;
  let extStrengths3;
  let extWeaknesses1;
  let extWeaknesses2;
  let extWeaknesses3;

  let agrStrengths1;
  let agrStrengths2;
  let agrStrengths3;
  let agrWeaknesses1;
  let agrWeaknesses2;
  let agrWeaknesses3;

  let staStrengths1;
  let staStrengths2;
  let staStrengths3;
  let staWeaknesses1;
  let staWeaknesses2;
  let staWeaknesses3;

  let conAkaText = '';
  let emoAkaText = '';

  let akaText = '';

  let showOpeStrengths = true;
  let showConStrengths = true;
  let showExtStrengths = true;
  let showAgrStrengths = true;
  let showStaStrengths = true;


  if (personalityData == null) {
    personalityData = ({ "ope": 4, "con": 4, "ext": 4, "agr": 4, "sta": 4 });
  }

  const opeLevel = personalityData.ope;
  const conLevel = personalityData.con;
  const extLevel = personalityData.ext;
  const agrLevel = personalityData.agr;
  const staLevel = personalityData.sta;

  //const opeLevel = 7; 
  //const conLevel = 6; 
  //const extLevel = 4; 
  //const agrLevel = 2;
  //const staLevel = 6; 

  if (opeLevel <= 1) {
    opeTitle = "Highly closed to experience";
    opeText1 = opeResultsText.ope1text1;
    opeText2 = opeResultsText.ope1text2;
    opeText3 = opeResultsText.ope1text3;
  }
  else if (opeLevel <= 2) {
    opeTitle = "Moderately closed to experience";
    opeText1 = opeResultsText.ope2text1;
    opeText2 = opeResultsText.ope2text2;
    opeText3 = opeResultsText.ope2text3;
  }
  else if (opeLevel <= 3) {
    opeTitle = "Somewhat closed to experience";
    opeText1 = opeResultsText.ope3text1;
    opeText2 = opeResultsText.ope3text2;
    opeText3 = opeResultsText.ope3text3;
  }
  else if (opeLevel <= 4) {
    opeTitle = "Neither open nor closed to experience";
    opeText1 = opeResultsText.ope4text1;
    opeText2 = opeResultsText.ope4text2;
    opeText3 = opeResultsText.ope4text3;
  }
  else if (opeLevel <= 5) {
    opeTitle = "Somewhat open to experience";
    opeText1 = opeResultsText.ope5text1;
    opeText2 = opeResultsText.ope5text2;
    opeText3 = opeResultsText.ope5text3;
  }
  else if (opeLevel <= 6) {
    opeTitle = "Moderately open to experience";
    opeText1 = opeResultsText.ope6text1;
    opeText2 = opeResultsText.ope6text2;
    opeText3 = opeResultsText.ope6text3;
  }
  else if (opeLevel <= 7) {
    opeTitle = "Highly open to experience";
    opeText1 = opeResultsText.ope7text1;
    opeText2 = opeResultsText.ope7text2;
    opeText3 = opeResultsText.ope7text3;
  }


  if (conLevel <= 1) {
    conTitle = "Highly unstructured";
    conText1 = conResultsText.con1text1;
    conText2 = conResultsText.con1text2;
    conText3 = conResultsText.con1text3;
  }
  else if (conLevel <= 2) {
    conTitle = "Moderately unstructured";
    conText1 = conResultsText.con2text1;
    conText2 = conResultsText.con2text2;
    conText3 = conResultsText.con2text3;
  }
  else if (conLevel <= 3) {
    conTitle = "Somewhat unstructured";
    conText1 = conResultsText.con3text1;
    conText2 = conResultsText.con3text2;
    conText3 = conResultsText.con3text3;
  }
  else if (conLevel <= 4) {
    conTitle = "Neither structured nor unstructured";
    conText1 = conResultsText.con4text1;
    conText2 = conResultsText.con4text2;
    conText3 = conResultsText.con4text3;
  }
  else if (conLevel <= 5) {
    conTitle = "Somewhat structured";
    conText1 = conResultsText.con5text1;
    conText2 = conResultsText.con5text2;
    conText3 = conResultsText.con5text3;
  }
  else if (conLevel <= 6) {
    conTitle = "Moderately structured";
    conText1 = conResultsText.con6text1;
    conText2 = conResultsText.con6text2;
    conText3 = conResultsText.con6text3;
  }
  else if (conLevel <= 7) {
    conTitle = "Highly structured";
    conText1 = conResultsText.con7text1;
    conText2 = conResultsText.con7text2;
    conText3 = conResultsText.con7text3;
  }



  if (extLevel <= 1) {
    extTitle = "Highly introverted";
    extText1 = extResultsText.ext1text1;
    extText2 = extResultsText.ext1text2;
    extText3 = extResultsText.ext1text3;
  }
  else if (extLevel <= 2) {
    extTitle = "Moderately introverted";
    extText1 = extResultsText.ext2text1;
    extText2 = extResultsText.ext2text2;
    extText3 = extResultsText.ext2text3;
  }
  else if (extLevel <= 3) {
    extTitle = "Somewhat introverted";
    extText1 = extResultsText.ext3text1;
    extText2 = extResultsText.ext3text2;
    extText3 = extResultsText.ext3text3;
  }
  else if (extLevel <= 4) {
    extTitle = "Neither introverted nor extraverted";
    extText1 = extResultsText.ext4text1;
    extText2 = extResultsText.ext4text2;
    extText3 = extResultsText.ext4text3;
  }
  else if (extLevel <= 5) {
    extTitle = "Somewhat extraverted";
    extText1 = extResultsText.ext5text1;
    extText2 = extResultsText.ext5text2;
    extText3 = extResultsText.ext5text3;
  }
  else if (extLevel <= 6) {
    extTitle = "Moderately extraverted";
    extText1 = extResultsText.ext6text1;
    extText2 = extResultsText.ext6text2;
    extText3 = extResultsText.ext6text3;
  }
  else if (extLevel <= 7) {
    extTitle = "Highly extraverted";
    extText1 = extResultsText.ext7text1;
    extText2 = extResultsText.ext7text2;
    extText3 = extResultsText.ext7text3;
  }




  if (agrLevel <= 1) {
    agrTitle = "Highly disagreeable";
    agrText1 = agrResultsText.agr1text1;
    agrText2 = agrResultsText.agr1text2;
    agrText3 = agrResultsText.agr1text3;
  }
  else if (agrLevel <= 2) {
    agrTitle = "Moderately disagreeable";
    agrText1 = agrResultsText.agr2text1;
    agrText2 = agrResultsText.agr2text2;
    agrText3 = agrResultsText.agr2text3;
  }
  else if (agrLevel <= 3) {
    agrTitle = "Somewhat disagreeable";
    agrText1 = agrResultsText.agr3text1;
    agrText2 = agrResultsText.agr3text2;
    agrText3 = agrResultsText.agr3text3;
  }
  else if (agrLevel <= 4) {
    agrTitle = "Neither disagreeable nor agreeable";
    agrText1 = agrResultsText.agr4text1;
    agrText2 = agrResultsText.agr4text2;
    agrText3 = agrResultsText.agr4text3;
  }
  else if (agrLevel <= 5) {
    agrTitle = "Somewhat agreeable";
    agrText1 = agrResultsText.agr5text1;
    agrText2 = agrResultsText.agr5text2;
    agrText3 = agrResultsText.agr5text3;
  }
  else if (agrLevel <= 6) {
    agrTitle = "Moderately agreeable";
    agrText1 = agrResultsText.agr6text1;
    agrText2 = agrResultsText.agr6text2;
    agrText3 = agrResultsText.agr6text3;
  }
  else if (agrLevel <= 7) {
    agrTitle = "Highly agreeable";
    agrText1 = agrResultsText.agr7text1;
    agrText2 = agrResultsText.agr7text2;
    agrText3 = agrResultsText.agr7text3;
  }




  if (staLevel <= 1) {
    staTitle = "Highly emotionally variant";
    staText1 = staResultsText.sta1text1;
    staText2 = staResultsText.sta1text2;
    staText3 = staResultsText.sta1text3;
  }
  else if (staLevel <= 2) {
    staTitle = "Moderately emotionally variant";
    staText1 = staResultsText.sta2text1;
    staText2 = staResultsText.sta2text2;
    staText3 = staResultsText.sta2text3;
  }
  else if (staLevel <= 3) {
    staTitle = "Somewhat emotionally variant";
    staText1 = staResultsText.sta3text1;
    staText2 = staResultsText.sta3text2;
    staText3 = staResultsText.sta3text3;
  }
  else if (staLevel <= 4) {
    staTitle = "Neither emotionally stable nor  emotionally variant";
    staText1 = staResultsText.sta4text1;
    staText2 = staResultsText.sta4text2;
    staText3 = staResultsText.sta4text3;
  }
  else if (staLevel <= 5) {
    staTitle = "Somewhat emotionally stable";
    staText1 = staResultsText.sta5text1;
    staText2 = staResultsText.sta5text2;
    staText3 = staResultsText.sta5text3;
  }
  else if (staLevel <= 6) {
    staTitle = "Moderately emotionally stable";
    staText1 = staResultsText.sta6text1;
    staText2 = staResultsText.sta6text2;
    staText3 = staResultsText.sta6text3;
  }
  else if (staLevel <= 7) {
    staTitle = "Highly emotionally stable";
    staText1 = staResultsText.sta7text1;
    staText2 = staResultsText.sta7text2;
    staText3 = staResultsText.sta7text3;
  }


  if (opeLevel <= 4) {
    opeStrengths1 = strengthsText.cloStrength1;
    opeStrengths2 = strengthsText.cloStrength2;
    opeStrengths3 = strengthsText.cloStrength3;
    opeWeaknesses1 = strengthsText.cloWeakness1;
    opeWeaknesses2 = strengthsText.cloWeakness2;
    opeWeaknesses3 = strengthsText.cloWeakness3;
  }
  else {
    opeStrengths1 = strengthsText.opeStrength1;
    opeStrengths2 = strengthsText.opeStrength2;
    opeStrengths3 = strengthsText.opeStrength3;
    opeWeaknesses1 = strengthsText.opeWeakness1;
    opeWeaknesses2 = strengthsText.opeWeakness2;
    opeWeaknesses3 = strengthsText.opeWeakness3;
  }

  if (opeLevel == 4) {
    showOpeStrengths = false;
  }



  if (conLevel <= 4) {
    conStrengths1 = strengthsText.uncStrength1;
    conStrengths2 = strengthsText.uncStrength2;
    conStrengths3 = strengthsText.uncStrength3;
    conWeaknesses1 = strengthsText.uncWeakness1;
    conWeaknesses2 = strengthsText.uncWeakness2;
    conWeaknesses3 = strengthsText.uncWeakness3;
    conAkaText = 'Also known as: Unconscientious'
  }
  else {
    conStrengths1 = strengthsText.conStrength1;
    conStrengths2 = strengthsText.conStrength2;
    conStrengths3 = strengthsText.conStrength3;
    conWeaknesses1 = strengthsText.conWeakness1;
    conWeaknesses2 = strengthsText.conWeakness2;
    conWeaknesses3 = strengthsText.conWeakness3;
    conAkaText = 'Also known as: Conscientious'
  }

  if (conLevel == 4) {
    showConStrengths = false;
    conAkaText = 'Also known as: Conscientious / Unconscientious'
  }


  if (extLevel <= 4) {
    extStrengths1 = strengthsText.intStrength1;
    extStrengths2 = strengthsText.intStrength2;
    extStrengths3 = strengthsText.intStrength3;
    extWeaknesses1 = strengthsText.intWeakness1;
    extWeaknesses2 = strengthsText.intWeakness2;
    extWeaknesses3 = strengthsText.intWeakness3;
  }
  else {
    extStrengths1 = strengthsText.extStrength1;
    extStrengths2 = strengthsText.extStrength2;
    extStrengths3 = strengthsText.extStrength3;
    extWeaknesses1 = strengthsText.extWeakness1;
    extWeaknesses2 = strengthsText.extWeakness2;
    extWeaknesses3 = strengthsText.extWeakness3;
  }

  if (extLevel == 4) {
    showExtStrengths = false;
  }


  if (agrLevel <= 4) {
    agrStrengths1 = strengthsText.disStrength1;
    agrStrengths2 = strengthsText.disStrength2;
    agrStrengths3 = strengthsText.disStrength3;
    agrWeaknesses1 = strengthsText.disWeakness1;
    agrWeaknesses2 = strengthsText.disWeakness2;
    agrWeaknesses3 = strengthsText.disWeakness3;
  }
  else {
    agrStrengths1 = strengthsText.agrStrength1;
    agrStrengths2 = strengthsText.agrStrength2;
    agrStrengths3 = strengthsText.agrStrength3;
    agrWeaknesses1 = strengthsText.agrWeakness1;
    agrWeaknesses2 = strengthsText.agrWeakness2;
    agrWeaknesses3 = strengthsText.agrWeakness3;
  }

  if (agrLevel == 4) {
    showAgrStrengths = false;
  }

  if (staLevel <= 4) {
    staStrengths1 = strengthsText.neuStrength1;
    staStrengths2 = strengthsText.neuStrength2;
    staStrengths3 = strengthsText.neuStrength3;
    staWeaknesses1 = strengthsText.neuWeakness1;
    staWeaknesses2 = strengthsText.neuWeakness2;
    staWeaknesses3 = strengthsText.neuWeakness3;
    emoAkaText = 'Also known as: Neurotic'

  }
  else {
    staStrengths1 = strengthsText.staStrength1;
    staStrengths2 = strengthsText.staStrength2;
    staStrengths3 = strengthsText.staStrength3;
    staWeaknesses1 = strengthsText.staWeakness1;
    staWeaknesses2 = strengthsText.staWeakness2;
    staWeaknesses3 = strengthsText.staWeakness3;
  }

  if (staLevel == 4) {
    emoAkaText = 'Also known as: Emotionally stable / Neurotic'
    showStaStrengths = false;
  }


  let opeLogo;
  let conLogo;
  let extLogo;
  let agrLogo;
  let staLogo;

  let opeStrengthImage;
  let conStrengthImage;
  let extStrengthImage;
  let agrStrengthImage;
  let emoStrengthImage;


  let opeWeaknessImage;
  let conWeaknessImage;
  let extWeaknessImage;
  let agrWeaknessImage;
  let emoWeaknessImage;



  // setting correct logos

  if (opeLevel < 4) {
    opeLogo = O2;
    opeStrengthImage = CloStrength;
    opeWeaknessImage = CloWeakness;
  }
  else if (opeLevel > 4) {
    opeLogo = O;
    opeStrengthImage = OpeStrength;
    opeWeaknessImage = OpeWeakness;
  }
  else {
    opeLogo = OpeClo;
    opeStrengthImage = CloStrength;
    opeWeaknessImage = CloWeakness;
  }

  if (conLevel < 4) {
    conLogo = C2;
    conStrengthImage = UncStrength;
    conWeaknessImage = UncWeakness;
  }
  else if (conLevel > 4) {
    conLogo = C;
    conStrengthImage = ConStrength;
    conWeaknessImage = ConWeakness;
  }
  else {
    conLogo = ConUnc;
    conStrengthImage = UncStrength;
    conWeaknessImage = UncWeakness;
  }

  if (extLevel < 4) {
    extLogo = E2;
    extStrengthImage = IntStrength;
    extWeaknessImage = IntWeakness;
  }
  else if (extLevel > 4) {
    extLogo = E;
    extStrengthImage = ExtStrength;
    extWeaknessImage = ExtWeakness;
  }
  else {
    extLogo = ExtInt;
    extStrengthImage = IntStrength;
    extWeaknessImage = IntWeakness;
  }

  if (agrLevel < 4) {
    agrLogo = A2;
    agrStrengthImage = DisStrength;
    agrWeaknessImage = DisWeakness;
  }
  else if (agrLevel > 4) {
    agrLogo = A;
    agrStrengthImage = AgrStrength;
    agrWeaknessImage = AgrWeakness;
  }
  else {
    agrLogo = AgrDis;
    agrStrengthImage = DisStrength;
    agrWeaknessImage = DisWeakness;
  }

  if (staLevel < 4) {
    staLogo = S2;
    emoStrengthImage = NeuStrength;
    emoWeaknessImage = NeuWeakness;
  }
  else if (staLevel > 4) {
    staLogo = S;
    emoStrengthImage = EmoStrength;
    emoWeaknessImage = EmoWeakness;
  }
  else {
    staLogo = EmoNeu;
    emoStrengthImage = NeuStrength;
    emoWeaknessImage = NeuWeakness;
  }



  return (
    <div className="results">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >
        <div className="heading-section" >
          <div className="flex-divider">
            <img src={Logo} className="bigfive-logo" alt="logo" />
            <div>
              <div className="heading-section-title"> Your results:</div>
            </div>
          </div>
        </div>

        <div className="result-icons" id="to-export">

          <motion.div className="result"
            initial={{ opacity: 0, y: -10 }}
            animate={
              { opacity: 1, y: 0, transition: { delay: 0.1 } }}

          >
            <motion.img className="result-icon" src={opeLogo} alt="logo"
              whileHover={
                {
                  y: [null, -5, 0],
                  transition: { duration: 0.3 },
                }
              }
            />
            <div className="result-text">
              {opeTitle}
            </div>
            {/*<div className="result-letter">
            O
  </div>*/}

          </motion.div>
          <motion.div className="result"
            initial={{ opacity: 0, y: -10 }}
            animate={
              { opacity: 1, y: 0, transition: { delay: 0.2 } }}

          >
            <motion.img className="result-icon" src={conLogo} alt="logo"
              whileHover={
                {
                  y: [null, -5, 0],
                  transition: { duration: 0.3 },
                }
              } />
            <div className="result-text">
              {conTitle}
            </div>
            {/*
          <div className="result-letter">
            C
          </div>
*/}
          </motion.div>
          <motion.div className="result"
            initial={{ opacity: 0, y: -10 }}
            animate={
              { opacity: 1, y: 0, transition: { delay: 0.3 } }}
          >
            <motion.img className="result-icon" src={extLogo} alt="logo"
              whileHover={
                {
                  y: [null, -5, 0],
                  transition: { duration: 0.3 },
                }
              }
            />
            <div className="result-text">
              {extTitle}
            </div>
            {/*
          <div className="result-letter">
            E
          </div>
*/}
          </motion.div>
          <motion.div className="result"
            initial={{ opacity: 0, y: -10 }}
            animate={
              { opacity: 1, y: 0, transition: { delay: 0.4 } }}
          >
            <motion.img className="result-icon" src={agrLogo} alt="logo"
              whileHover={
                {
                  y: [null, -5, 0],
                  transition: { duration: 0.3 },
                }
              } />
            <div className="result-text">
              {agrTitle}
            </div>
            {/*
          <div className="result-letter">
            A
          </div>
*/}
          </motion.div>
          <motion.div className="result"
            initial={{ opacity: 0, y: -10 }}
            animate={
              { opacity: 1, y: 0, transition: { delay: 0.5 } }}
          >
            <motion.img className="result-icon" src={staLogo} alt="logo"
              whileHover={
                {
                  y: [null, -5, 0],
                  transition: { duration: 0.3 },
                }
              }
            />
            <div className="result-text">
              {staTitle}
            </div>
            {/*
          <div className="result-letter">
            S
          </div>
*/}
          </motion.div>


        </div>


        <Facet facetTitle={opeTitle} facetLogo={opeLogo} facetText1={opeText1} facetText2={opeText2} facetText3={opeText3}
          strength1={opeStrengths1} strength2={opeStrengths2} strength3={opeStrengths3} weakness1={opeWeaknesses1} weakness2={opeWeaknesses2}
          weakness3={opeWeaknesses3} showStrengths={showOpeStrengths} strengthImage={opeStrengthImage} weaknessImage={opeWeaknessImage}
          akaText={akaText} level={opeLevel - 1} facetType={"ope"}
        />
        <Facet facetTitle={conTitle} facetLogo={conLogo} facetText1={conText1} facetText2={conText2} facetText3={conText3}
          strength1={conStrengths1} strength2={conStrengths2} strength3={conStrengths3} weakness1={conWeaknesses1} weakness2={conWeaknesses2}
          weakness3={conWeaknesses3} showStrengths={showConStrengths} strengthImage={conStrengthImage} weaknessImage={conWeaknessImage}
          akaText={conAkaText} level={conLevel - 1} facetType={"con"}
        />
        <Facet facetTitle={extTitle} facetLogo={extLogo} facetText1={extText1} facetText2={extText2} facetText3={extText3}
          strength1={extStrengths1} strength2={extStrengths2} strength3={extStrengths3} weakness1={extWeaknesses1} weakness2={extWeaknesses2}
          weakness3={extWeaknesses3} showStrengths={showExtStrengths} strengthImage={extStrengthImage} weaknessImage={extWeaknessImage}
          akaText={akaText} level={extLevel - 1} facetType={"ext"}
        />
        <Facet facetTitle={agrTitle} facetLogo={agrLogo} facetText1={agrText1} facetText2={agrText2} facetText3={agrText3}
          strength1={agrStrengths1} strength2={agrStrengths2} strength3={agrStrengths3} weakness1={agrWeaknesses1} weakness2={agrWeaknesses2}
          weakness3={agrWeaknesses3} showStrengths={showAgrStrengths} strengthImage={agrStrengthImage} weaknessImage={agrWeaknessImage}
          akaText={akaText} level={agrLevel - 1} facetType={"agr"}
        />
        <Facet facetTitle={staTitle} facetLogo={staLogo} facetText1={staText1} facetText2={staText2} facetText3={staText3}
          strength1={staStrengths1} strength2={staStrengths2} strength3={staStrengths3} weakness1={staWeaknesses1} weakness2={staWeaknesses2}
          weakness3={staWeaknesses3} showStrengths={showStaStrengths} strengthImage={emoStrengthImage} weaknessImage={emoWeaknessImage}
          akaText={emoAkaText} level={staLevel - 1} facetType={"sta"}
        />

        <div className="reset-test-text" onClick={() => { setModalOpen(true) }}>
          Delete results and retake test
        </div>

      </motion.div>
    </div>
  );
}


const Facet = ({ facetTitle, facetLogo, facetText1, facetText2, facetText3, strength1, strength2, strength3, weakness1, weakness2,
  weakness3, showStrengths, strengthImage, weaknessImage, akaText, level, facetType }) => {

  return (
    <div className="facet">
      <div className="facet-title" >
        {facetTitle}
      </div>

      <div className="subheading"> {akaText}</div>
      <div className="flex-divider">
        <div className="facet-result-chart">
          <ResultChart facetType={facetType} level={level} />
        </div>
       
        <div className= "facet-text-right">
          {facetText1}
          <div className="small-vertical-spacer" />
          {facetText2}
        </div>
      </div>

      <div className="small-vertical-spacer" />
      <div>
        {facetText3}
      </div>

      <RenderStrengths strength1={strength1} strength2={strength2} strength3={strength3}
        weakness1={weakness1} weakness2={weakness2} weakness3={weakness3} showStrengths={showStrengths} strengthImage={strengthImage} weaknessImage={weaknessImage} />
    </div>

  );

}



const RenderStrengths = ({ strength1, strength2, strength3, weakness1, weakness2, weakness3, showStrengths, strengthImage, weaknessImage }) => {

  if (showStrengths == true) {
    return (
      <>
        <div className="facet-strengths">
          <div className="flex-divider">
            <motion.div whileHover={
              {
                y: [null, -5, 0],
                transition: { duration: 0.3 },
              }
            }>
              <img className="strength-image" src={strengthImage} alt="logo" />
            </motion.div>
            <div className="strengths-text">
              <div className="strengths-title">
                Strengths:
              </div>
              <div className="strengths-p">
                <div className="strength">
                  • {strength1}
                </div>
                <div className="strength">
                  • {strength2}
                </div>
                <div className="strength">
                  • {strength3}
                </div>
              </div>
            </div>
          </div>


        </div>



        <div className="facet-strengths">
          <div className="flex-divider">
            <motion.div whileHover={
              {
                y: [null, -5, 0],
                transition: { duration: 0.3 },
              }
            }>
              <img className="strength-image" src={weaknessImage} alt="logo" />
            </motion.div>
            <div className="strengths-text">
              <div className="strengths-title">
                Weaknesses:
              </div>
              <div className="strengths-p">
                <div className="strength">
                  • {weakness1}
                </div>
                <div className="strength">
                  • {weakness2}
                </div>
                <div className="strength">
                  • {weakness3}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>

    );
  }
  else {
    return (<></>);
  }

}

{/*
var node = document.getElementById('to-export');

htmlToImage.toPng(node)
  .then(function (dataUrl) {
    var img = new Image();
    img.src = dataUrl;
    document.body.appendChild(img);
  })
  .catch(function (error) {
    console.error('oops, something went wrong!', error);
  });
*/}



export default Results;