
export const strengthsText = {


    opeStrength1: `Creative and flexible in their thinking  
`,
    opeStrength2: `Always curious`,
    opeStrength3: `Enjoy intellectual pursuits and aesthetic experiences 
`
    ,
    opeWeakness1: `Struggle with routine and get bored easily
`,
    opeWeakness2: `Favour a risky life strategy over a safe one
`,
    opeWeakness3: `Do not follow order or hierarchies`,
    cloStrength1: `Enjoy familiarity and routine
`,
    cloStrength2: `Don’t waste energy pursuing risky strategies`,
    cloStrength3: `Are grounded and realistic in their thinking

`
    ,
    cloWeakness1: `Can be overly inflexible in their thinking
`,
    cloWeakness2: `May be overly dependent on routine
`,
    cloWeakness3: `Can have trouble seeing the value of creative ideas
`,

    conStrength1: `Are organised and stick to plans
`,
    conStrength2: `Are hard workers 
`,
    conStrength3: `Like to maintain clean environments around them

`,
    conWeakness1: `Can be judgemental
`,
    conWeakness2: `May be workaholics and overly perfectionistic

`,
    conWeakness3: `Can be overly rigid and strict

`,
    uncStrength1: `Are spontaneous and enjoy living in the moment
`,
    uncStrength2: `Are more relaxed and less judgemental than structured people
`,
    uncStrength3: `Shake things up with a chaotic, unorganised approach
`,
    uncWeakness1: `Have difficulties making and sticking to plans
`,
    uncWeakness2: `Can have problems resisting addictive behaviours
    
`,
    uncWeakness3: `May be messy and unorganised

`,
    extStrength1: `Create fun in groups by being enthusiastic and energetic
`,
    extStrength2: `Have large social circles

`,
    extStrength3: `Make friends easily

`,
    extWeakness1: `May struggle with listening to others
`,
    extWeakness2: `Can be unhappy when they are on their own
`,
    extWeakness3: `May dominate and be overly attention-seeking in social environments
`,
    intStrength1: `Enjoy spending time on their own
`,
    intStrength2: `Function well in a more quiet, thoughtful environment
`,
    intStrength3: `Listen to others and do not seek attention 

`,
    intWeakness1: `Can struggle making friends 
`,
    intWeakness2: `May have difficulties being assertive in social environments
`,
    intWeakness3: `Can get burnt out in situations where they need to be around people
`,
    agrStrength1: `Get on easily with other people and are generally liked
`,
    agrStrength2: `Put other people’s needs above their own and are compassionate

`,
    agrStrength3: `Foster unity in groups


`,
    agrWeakness1: `Find it hard to stand up for themselves and for otherss
`,
    agrWeakness2: `Can sacrifice their own wellbeing too much
`,
    agrWeakness3: `May be overly deferring to others & be pushovers
`,
    disStrength1: `Do well in competitive situations
`,
    disStrength2: `Tell the truth even if it hurts others feelings and call out others when necessary  
`,
    disStrength3: `Prioritise their own wellbeing over others

`,
    disWeakness1: `Can have issues being seen as likeable by others
`,
    disWeakness2: `May cause unnecessary conflict
`,
    disWeakness3: `Can be overly self-centred and combative

`,
    staStrength1: `Able to handle stressful situations and do well under pressure. 
`,
    staStrength2: `Less prone to negative emotional states than emotionally unstable people
`,
    staStrength3: `Less self-critical and more confident
`,
    staWeakness1: `May ignore negative consequences that they should have considered

`,
    staWeakness2: `May not be as self-aware or self-critical as they should be
`,
    staWeakness3: ` Can miss real issues because they’re not worried about them

`,
    neuStrength1: `Feel emotions deeply
`,
    neuStrength2: `May notice threats and potential problems before others
`,
    neuStrength3: `Are self-aware

`,
    neuWeakness1: `Are more likely to suffer from negative emotional states than emotionally stable people
`,
    neuWeakness2: `Don’t do well in stressful environments
`,
    neuWeakness3: `Can be shy and overly self-critical


`,





}

