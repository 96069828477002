import React from 'react';
import { Link } from 'react-router-dom';

import './navbar.scss';

import Icon from './images/small-icon.png';
import Placeholder from './images/placeholder-profile.svg';

import useUser from './hooks/useUser';


const NavBar = () => {

  const user = useUser();

  return (<nav>
    <div className="nav">
      <Link to='/' className="nav-left">
        <div className="normal-flex-divider">
          <div className="nav-icon-container">
            <img className="nav-icon" src={Icon} alt="small-icon" />
          </div>
          <div className="nav-text">
            The Big Five
          </div>
        </div>
      </Link>
      <div className="nav-divider"></div>
      <div className="nav-right">
        <Link to='/profile'>
          {user == null ?
            <div className="button special" > Log in / Register </div>
            :
            <div className="nav-user" >
              <div> <img src={Placeholder}  className="nav-user-icon" />  </div>

              <div className="nav-user-text"> {user.email} </div>
            </div>}
        </Link>
      </div>
    </div>
  </nav>);
}

export default NavBar;
