import React from 'react';

import { useRouteError } from 'react-router-dom';

const ErrorPage = () => {
    const error = useRouteError();
    
    return (
        <div>
            <div>An error has occured. </div>
            <div> {error.statusText || error.message} </div>
        </div>
    )
}

export default ErrorPage;
