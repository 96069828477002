import './App.css';
import './global-variables.scss';
import './pages/home.scss';

import { useEffect } from 'react';

import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import Navbar from './navbar.js';
import Footer from './footer.js';
import ErrorPage from './pages/error-page.js';
import React, { useState } from 'react';
import Home from './pages/home.js';
import TimeSelect from './pages/time-select.js';
import Profile from './pages/profile.js';
import PreTest from './pages/pre-test.js';
import Results from './pages/results.js';
import Test from './pages/test.js';
import ResultChart from './components/result-chart';


import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import useUser from './hooks/useUser';
import ScrollToTop from './hooks/scrollToTop';

import { firebaseApp, firebaseDB } from './firebase';

import { getAuth, getRedirectResult, GoogleAuthProvider } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';

import {motion} from 'framer-motion';


function App() {

  // Initialize Firebase
  const analytics = getAnalytics(firebaseApp);

  const location = useLocation;

  const [modalOpen, setModalOpen] = useState(false);
  const [personalityData, setPersonalityData] = useState(null);

  const user = useUser();
  const navigate = useNavigate();
  const auth = getAuth();

  // This is for getting the auth result from the google redirect
  getRedirectResult(auth)
    .then((result) => {
      // This gives you a Google Access Token. You can use it to access Google APIs.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      // The signed-in user info.
      const user = result.user;
      // IdP data available using getAdditionalUserInfo(result)
      // ...
    }).catch((error) => {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;
      // The email of the user's account used.
      //const email = error.customData.email;
      // The AuthCredential type that was used.
      const credential = GoogleAuthProvider.credentialFromError(error);
      // ...
    });


  /**
   * Checks any time modal is open to prevent scrolling on body
   */
  useEffect(() => {

    if (modalOpen == true) {
      document.body.style.overflow = "hidden";
    }
    else {
      document.body.style.overflow = "auto";
    }
  }, [modalOpen]);



  // check personality data any time user has changed
  useEffect(() => {
    checkData();
  }, [user]);


  const checkData = () => {

    const readData = async () => {

      //check if there is a user logged in
      if (user != null) {

        const docRef = doc(firebaseDB, "users", user.uid);
        const docSnap = await getDoc(docRef);

        // if there is associated data with the user
        if (docSnap.exists()) {

          localStorage.clear();

          if (docSnap.data().personalityData != null) {

            const personality = docSnap.data().personalityData;
            //console.log("Document data:", docSnap.data());
            setPersonalityData({
              "ope": personality.ope, "con": personality.con, "ext": personality.ext,
              "agr": personality.agr, "sta": personality.sta
            });
            //navigate('/results');
            return;
          }
          else {
            console.log("Personality data is null!");
          }
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
      }
    }

    // first, check if there is a user logged in and if there is data associated with the user
    readData();

    // if there is not a user logged in, then we check if there is data in local storage. 
    const localPersonalityData = localStorage.getItem("personalityData");

    console.log(localPersonalityData);

    if (localPersonalityData != null) {

      const parsedPersonalityData = JSON.parse(localPersonalityData);

      setPersonalityData({
        "ope": parseInt(parsedPersonalityData.ope), "con": parseInt(parsedPersonalityData.con),
        "ext": parseInt(parsedPersonalityData.ext),
        "agr": parseInt(parsedPersonalityData.agr), "sta": parseInt(parsedPersonalityData.sta)
      });

      console.log("got local data")
      navigate('/results');
      return;
    }
    console.log("No data logged ");
  }


  return (
    // the basename prop is so that i can deploy on github - might need to remove later on

    // I have removed "basename" prop from this - if need to add back in, use basename="big-five"
    <>
      <ScrollToTop />
      <div className="App"  >
        <Navbar />
        <div className="app-body">
          {
            modalOpen == true ?
              <div className="modal">
                <div className="modal-content">
                  <span className="close" onClick={() => { setModalOpen(false) }}>&times;</span>
                  <div className="modal-title">
                    Reset test confirmation
                  </div>
                  <div className="modal-body">
                    <div>Are you sure you want to reset? All saved information about your results will be lost. </div>
                    <button className="button secondary" onClick={ async () => {
                       if (user != null) {
                        const docRef = doc(firebaseDB, "users", user.uid);
                        await updateDoc(docRef, {
                          personalityData: null
                        });
                      }
                      localStorage.clear();
                      setPersonalityData(null);
                      setModalOpen(false);
                      navigate('/');
                    }}>Reset</button>
                  </div>
                </div>
              </div>
              : <></>
          }

          <Routes>

            <Route path="/" element={<Home completedTestExists={personalityData != null ? true : false} />}
              errorElement={<ErrorPage />} />
            <Route path="/profile" element={<Profile personalityData={personalityData} setPersonalityData={setPersonalityData} setModalOpen={setModalOpen} />} />
            <Route path="/time-select" element={<TimeSelect />} />
            <Route path="/pre-test" element={<PreTest />} />
            <Route path="/test" element={<Test setPersonalityData={setPersonalityData} />} />
            <Route path="/results" element={<Results personalityData={personalityData} setModalOpen={setModalOpen} />} />
            <Route path="/result-chart" element={<ResultChart />} />
            <Route path="*" element={<Home />} />

          </Routes>
        </div>
        <Footer />
      </div>
    </>

  );
}



export default App;
