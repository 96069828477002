
export const extResultsText = {


ext1text1: `People who are highly introverted gain a lot of energy from spending time alone, and lose a lot of energy from spending time with people. If they spend time with others, they need to seek solitude for a long time to recover. They prefer environments where they don’t have to be overly social. In social settings they are highly reserved, quiet, and let others take the lead.  
`,
ext1text2: `Highly introverted people strongly want to spend time alone. Their social circles are smaller than average, and they prefer talking to people one-on-one rather than in groups. They avoid parties or situations in which they will be the centre of attention.   `,

ext1text3: `People who are highly introverted are not assertive or attention-seeking in social settings. They would prefer to give a thought-out response rather than an immediate one. They don’t get overly excited or loud when around others. They avoid volunteering for leadership positions, and will let others speak before them.
`
,



ext2text1: `
People who are moderately introverted gain energy from spending time alone, and lose energy from spending time with people. If they spend time with others, they need to seek solitude to recover. They prefer environments where they don’t have to be overly social. In social settings they are reserved, quiet, and let others take the lead. 

`,

ext2text2: `
Moderately introverted people want to spend time alone. Their social circles are smaller than average, and they prefer talking to people one-on-one rather than in groups. They avoid parties or situations in which they will be the centre of attention.  
`,

ext2text3:`People who are moderately introverted are not assertive or attention-seeking in social settings. They would prefer to give a thought-out response rather than an immediate one. They don’t get overly excited or loud when around others. They avoid volunteering for leadership positions, and will let others speak before them. `,




ext3text1: `People who are somewhat introverted gain a moderate amount of energy from spending time alone, and lose a moderate amount of energy from spending time with people. If they spend time with others, they may need to seek solitude to recover. They tend to seek environments where they don’t have to be overly social. In social settings they can be reserved, quiet, and let others take the lead. 
`,
ext3text2: `Somewhat introverted people have a preference for spending time alone. Their social circles are smaller than average, and they prefer talking to people one-on-one rather than in groups. They may avoid parties or situations in which they will be the centre of attention. 
`,
ext3text3: `People who are somewhat introverted tend not to be assertive or attention-seeking in social settings. They would most likely prefer to give a thought-out response rather than an immediate one. They may not get overly excited or loud when around others. They do not particularly want to be in leadership positions, and may let others speak before them. 
`,





ext4text1: `People who are neither extraverted nor introverted are not particularly high in extraversion or high in introversion compared with others. 
`,
ext4text2: `
People who are extraverted gain energy from being around others, whereas introverted people gain energy from spending time on their own. People who are somewhere in-between will seek out a mix of these two, where they can spend some time being social, but also have time on their own to recover. 
`,
ext4text3: `People who are extraverted are enthusiastic, assertive, and attention seeking, whereas people who are introverted are reserved, quiet and prefer to not have attention on them. People who are in-between are not overly enthusiastic or assertive, nor are they overly reserved and quiet. They have moderately sized friend-circles when compared to others, and enjoy a mix of one-on-one conversations and group social environments. 

`,

ext5text1:` 
People who are somewhat extraverted generally gain energy from being around other people. They have a tendency to seek out environments where they can be around others. In social settings, they can be assertive, enthusiastic, and enjoy leadership positions. They may not mind being the centre of attention. 
`,
ext5text2:`Somewhat extraverted people have a preference for collecting social groups around themselves. They may not enjoy spending time alone, and have larger friend networks than average. They may enjoy being in situations where they can show off, such as parties, and can be okay with being in the spotlight. Even though extraverted people aim to be around other people, they do not necessarily have to like them, as this is more associated with trait agreeableness. 
`,
ext5text3:`People who are somewhat extraverted tend to be enthusiastic and assertive. In conversations they can get excited easily, and may be explosive in their speech patterns and interrupt more often. They can dominate the conversation, and may be okay with leading it. They may enjoy being in leadership roles and working in positions where they can interact with others. 

`,



ext6text1:`People who are moderately extraverted gain energy from being around other people. They seek out environments where they can be around others. In social settings, they are assertive, enjoy being leaders, and are enthusiastic. They enjoy being the centre of attention. 
`,
ext6text2:`
Moderately extraverted people create and collect social groups around themselves. They do not enjoy spending time alone, and have large friend networks. They enjoy being in situations where they can show off, such as parties, and have no problems being in the spotlight. Even though extraverted people aim to be around other people, they do not necessarily have to like them, as this is more associated with trait agreeableness. 

`,
ext6text3:`
People who are moderately extraverted are enthusiastic and assertive. In conversations they get excited easily, and tend to be explosive in their speech patterns and interrupt more often. They often dominate the conversation, and have no problems leading it. They enjoy being in leadership roles and working in positions where they can interact with others.

`,

ext7text1:`People who are highly extraverted gain lots of energy from being around other people. They seek out environments where they can constantly be around others. In social settings, they are assertive, enjoy being leaders, and are enthusiastic. They highly enjoy being the centre of attention. 
`,
ext7text2:`
Highly extraverted people create and collect large social groups around themselves. They do not enjoy spending time alone, and have big friend networks. They strongly enjoy being in situations where they can show off, such as parties, and have no problems being in the spotlight. Even though extraverted people aim to be around other people, they do not necessarily have to like them, as this is more associated with trait agreeableness. 
`,
ext7text3:`
People who are highly extraverted are enthusiastic and assertive. In conversations they get excited easily, and tend to be explosive in their speech patterns and interrupt more often. They often dominate the conversation, and have no problems leading it. They strongly enjoy being in leadership roles and working in positions where they can interact with others.`


}

