
import {useState, useEffect} from 'react';
import {getAuth, onAuthStateChanged } from 'firebase/auth';


function useUser(){
    const auth = getAuth();
    const [user, setUser] = useState(null);

    useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user) => {

      setUser(user);
    
    });

    return unsub;

}, []);

return user;


}

export default useUser;