import React from 'react';

import './pages/home.scss';

import Mail from './images/mail.png';
import Twitter from './images/twitter.png';


const Footer = () => {

  return (
    <div className="footer-wrapper">
      <div className="footer">
        <div className="footer-line-container">
          <div className="footer-line"> <img src={Mail} width="15" height="15" alt="mail" /> <div className="footer-text"> Contact / support: thebigfiveapp@gmail.com </div> </div>
          <a className="footer-link" href="https://twitter.com/BigFiveTest" target="_blank">  <img src={Twitter} width="15" height="15" alt="twitter" /> 
           <div className="footer-text"> https://twitter.com/BigFiveTest</div> </a>
        </div>
        <div className="footer-line"> <div className="footer-text"> copyright @ 2023 Jack Upton Technologies Limited </div> </div>
      </div>
    </div>
  );
}

export default Footer;
