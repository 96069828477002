// Import the functions you need from the SDKs you need


import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";


// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries


// Your web app's Firebase configuration

// For Firebase JS SDK v7.20.0 and later, measurementId is optional

const firebaseConfig = {

  apiKey: "AIzaSyBAV6Nir6dwS6eUJY_OeNDzf8HSxFaYvTw",

  authDomain: "the-big-five-46f4c.firebaseapp.com",

  projectId: "the-big-five-46f4c",

  storageBucket: "the-big-five-46f4c.appspot.com",

  messagingSenderId: "860854877053",

  appId: "1:860854877053:web:153e36a27ea58a7dbe6a14",

  measurementId: "G-W4MHDF25D3"

};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const firebaseDB = getFirestore(firebaseApp);




export {
  firebaseApp,
  firebaseDB
}
