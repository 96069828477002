
export const agrResultsText = {


agr1text1: `People who are highly disagreeable put their own needs ahead of others. They don't care about other people’s feelings, and are blunt and honest. They are very competitive and are okay with conflict with other people, and may even enjoy it. They view themselves as being in competition with others. They tend to think in more self-centred terms than agreeable people. 

`,
agr1text2: `Highly disagreeable people have a more pessimistic outlook towards other people than agreeable people. They don’t particularly care about people’s feelings. Disagreeable people know what they want, and are willing to fight to get it. In exchanges with others, they would prefer to take what they can now rather than cooperating in a long-term fashion. They fight for their own needs instead of others.  `,
agr1text3: `
People who are highly disagreeable are very blunt and impolite. They advocate for themselves, and have no problem arguing and being in combat with others. For this reason they do well in competitive environments. They are not bothered by the prospect of being ostracised socially, and may have issues being seen in a positive light by others. 
 `
,



agr2text1: `People who are moderately disagreeable put their own needs ahead of others. They don't care about other people’s feelings, and are blunt and honest. They are competitive and don’t mind conflict with other people, and may even enjoy it. They view themselves as being in competition with others. They tend to think in more self-centred terms than agreeable people. 
`,

agr2text2: `Moderately disagreeable people have a more pessimistic outlook towards other people than agreeable people. They don’t particularly care about people’s feelings. Disagreeable people know what they want, and are willing to fight to get it. In exchanges with others, they would prefer to take what they can now rather than cooperating in a long-term fashion. They fight for their own needs instead of others. 
`,

agr2text3:`People who are moderately disagreeable are blunt and impolite. They advocate for themselves, and have no problem arguing and being in combat with others. For this reason they do well in competitive environments. They are not bothered by the prospect of being ostracised socially, and may have issues being seen in a positive light by others.  `,




agr3text1: `People who are somewhat disagreeable tend to put their own needs ahead of others. They care less about other people’s feelings than the average person, and can be blunt and honest. They tend to be competitive and usually don’t mind conflict with other people, and may even enjoy it. They often view themselves as being in competition with others. They may think in more self-centred terms than agreeable people. 
`,
agr3text2: `Somewhat disagreeable people can have a more pessimistic outlook towards other people than agreeable people. They may not particularly care about people’s feelings. Somewhat disagreeable people generally know what they want, and are okay with fighting to get it. In exchanges with others, they have a preference for taking what they can now rather than cooperating in a long-term fashion. They may fight for their own needs instead of others. 
`,
agr3text3: `People who are somewhat disagreeable can be blunt and impolite. They generally prefer to advocate for themselves, and usually don’t mind arguing and being in combat with others. For this reason they can do well in competitive environments. They may not be particularly bothered by the prospect of being ostracised socially, and can have issues being seen in a positive light by others. 


`,





agr4text1: `
People who are neither agreeable nor disagreeable are not particularly high in agreeableness or disagreeableness compared to others. 

`,
agr4text2: `
People who are agreeable care deeply about other people and prioritise other people’s needs over their own. Disagreeable people are competitive and put their own needs over others. People who are somewhere in-between are not overly compassionate towards other people, but are also not overly combative. There will be times where they put other people’s needs over their own, and times where they take a more direct, competitive approach. 
`,
agr4text3: ` People who are agreeable would prefer to avoid conflict, whereas disagreeable people don’t mind it and advocate for themselves. People who are neither agreeable nor disagreeable are flexible and will sometimes aim to avoid conflict if the situation calls for it, but will also not shy away from it when necessary. They are not especially rude and impolite towards others, but also not overly compassionate and soft-hearted.  `,

agr5text1:`People who are somewhat agreeable can be kind-hearted, polite, and empathetic towards others. They may seek deep, cooperative relationships in their lives. They tend to feel others' feelings and can feel happy if others are happy, or upset if others are upset. They have a preference for unity in social settings as opposed to competition and argumentation. 
`,
agr5text2:`
Somewhat agreeable people have a disposition towards caring about others’ feelings. They tend to be altruists, putting others' needs ahead of their own. They may have a positive disposition towards others, and can be described as being easy to get along with. In transactions between people, they would generally prefer that both parties leave feeling happy, even if it means that they take a short-term loss. Somewhat agreeable people may seek long-term romantic relationships with others and report feeling better when they are in one. 
 `,

agr5text3:`Somewhat agreeable people tend to not enjoy conflict, and aim to avoid it. They may delay or avoid saying how they really feel if they think it will hurt someone. If they feel as though they have hurt or offended someone, they can feel stressed, especially if combined with low emotional stability. They can worry about being seen badly by other people. 
`,



agr6text1:`People who are moderately agreeable are kind-hearted, polite, and empathetic towards others. They seek deep, cooperative relationships in their lives. They feel others' feelings and are happy if others are happy, or upset if others are upset. They aim to foster unity in social settings as opposed to competition and argumentation. 
`,
agr6text2:`Moderately agreeable people care about others’ feelings. They are altruists, putting others' needs ahead of their own. They have a positive disposition towards others, and are described as being easy to get along with. In transactions between people, they would prefer that both parties leave feeling happy, even if it means that they take a short-term loss. Moderately agreeable people seek long-term romantic relationships with others and report feeling better when they are in one. 
`,
agr6text3:`
Moderately agreeable people do not enjoy conflict, and aim to avoid it. Because of this they can be seen as pushovers. They may delay or avoid saying how they really feel if they think it will hurt someone. If they feel as though they have hurt or offended someone, they feel stressed, especially if combined with low emotional stability. They worry about being seen badly by other people. 
`,

agr7text1:`
People who are highly agreeable are very kind-hearted, polite, and empathetic towards others. They seek deep, cooperative relationships in their lives. They feel others' feelings deeply and are happy if others are happy, or upset if others are upset. They aim to foster unity in social settings as opposed to competition and argumentation. 
`,
agr7text2:`

Highly agreeable people care strongly about others’ feelings. They are altruists, putting others' needs ahead of their own. They have a highly positive disposition towards others, and are described as being easy to get along with. In transactions between people, they would prefer that both parties leave feeling happy, even if it means that they take a short-term loss. Highly agreeable people seek long-term romantic relationships with others and report feeling better when they are in one. 

`,
agr7text3:`
Highly agreeable people do not enjoy conflict, and aim to avoid it. Because of this they can be seen as pushovers. They may delay or avoid saying how they really feel if they think it will hurt someone. If they feel as though they have hurt or offended someone, they feel stressed, especially if combined with low emotional stability. They worry strongly about being seen badly by other people. 
 `


}

