import React from 'react';

import Logo from './../images/logo.png';
import Chevron from './../images/chevron.png';

import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';


const PreTest = () => {

  return (
    <div className="pre-test-container">
       <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
      >

      <div className="heading-section">
        <div className="flex-divider">
          <img className="bigfive-logo" width="297" height="363" src={Logo} alt="logo" />
          <div>
            <div className="heading-section-title"> Medium length test: 50 questions </div>
          </div>
        </div>
      </div>
      <div className="text-box">
        <div>
          Make sure you are in a quiet space where you know you will not be interrupted for the duration of the test. Try to answer each question honestly. Aim to not be overly flattering to yourself, nor overly self-critical. Do not take the test if you are overly tired or in an extreme emotional state. If you get stuck on a question, go with your instinct.
        </div>
        <div className="button-container">
          <Link to="/test">
            <button className="button secondary">
              <div className="button-text">
                Begin test
              </div>
              <img className="button-icon" src={Chevron} alt="chevron" />
            </button>
          </Link>
        </div>
      </div>
      </motion.div>
    </div>
  );
}

export default PreTest;
